import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from "gatsby"
import Img from 'gatsby-image'
import AnimateComponent from '../components/animate-component'
import SpecializationCarousel from '../components/specialization-carousel'

class PostTemplate extends React.Component {





    render () {

    const post = this.props.data.allWordpressPost.edges[0].node;

        return (
            <Layout>
                <SEO title={`${post.title} | Świecki Kancelaria`} />
                <AnimateComponent
                    styleName=""
                >
                    <div className="post__container">
                            <p className="specialization-details__back-arrow" onClick={()=> window.history.back()}>
                                Powrót
                            </p>
                        <header>
                            <div aos='fade-up' className=' wow fadeInUp' delay='0'>

                                <h1 className='post__title' dangerouslySetInnerHTML={{ __html: post.title }} />
                                {/* {post.featured_media &&

                                <Img
                                fluid={post.featured_media.localFile.childImageSharp.fluid}
                                className="post__image"
                            />
                                }


                             {post.acf.post_image && !post.featured_media &&

                                <img
                                src={post.acf.post_image}
                                className="post__image"
                            />} */}

                                  {post.content !== '' &&
                                    <div className="p_normal post__text" dangerouslySetInnerHTML={{ __html: `${post.content}` }} />
                                }

                            </div>
                        </header>

                    </div>

                </AnimateComponent>

            </Layout>
        )
    }
}

export default PostTemplate
export const pageQuery = graphql `
query($id: String!) {
    allWordpressPost( filter: { id: {eq : $id}}){
        edges {
          node {
            title
            content
            slug
            id
            excerpt
            # featured_media{
            #     localFile {
            #         childImageSharp {
            #             fluid(maxWidth: 1170, quality: 90) {
            #                 ...GatsbyImageSharpFluid
            #             }
            #         }
            #     }
            # }
            # acf {
            #     post_image

            # }

          }

        }
      }

}
`